"use strict";

//Menu 
const menubar = document.getElementById('menubar');
const menu = document.getElementById('menu');
menubar.addEventListener('click', () => {
  if (menubar.getAttribute('aria-selected') === 'false') {
    menubar.setAttribute('aria-selected', 'true');
    menu.setAttribute('aria-hidden', 'false');
  } else {
    menubar.setAttribute('aria-selected', 'false');
    menu.setAttribute('aria-hidden', 'true');
    menu.classList.remove('display-slidedown');
  }
});

//Top Image & Texts
const imageDataviz = document.getElementsByClassName('dataviz-image_inner');
const imageUiuxvisual = document.getElementsByClassName('uiux-image_inner');
const imageFrontend = document.getElementsByClassName('frontend-image_inner');
const imageGiscart = document.getElementsByClassName('gis-image_inner');

const textDataviz = document.getElementsByClassName('dataviz-text');
const textUiuxvisual = document.getElementsByClassName('uiux-text');
const textFrontend = document.getElementsByClassName('frontend-text');
const textGiscart = document.getElementsByClassName('gis-text');

// When hover top images...
imageDataviz[0].addEventListener("mouseenter", ()=> {
  textDataviz[0].classList.add("text-relate");
});
imageDataviz[0].addEventListener("mouseleave", ()=> {
  textDataviz[0].classList.remove("text-relate");
});
imageUiuxvisual[0].addEventListener("mouseenter", ()=> {
  textUiuxvisual[0].classList.add("text-relate");
});
imageUiuxvisual[0].addEventListener("mouseleave", ()=> {
  textUiuxvisual[0].classList.remove("text-relate");
});
imageFrontend[0].addEventListener("mouseenter", ()=> {
  textFrontend[0].classList.add("text-relate");
});
imageFrontend[0].addEventListener("mouseleave", ()=> {
  textFrontend[0].classList.remove("text-relate");
});
imageGiscart[0].addEventListener("mouseenter", ()=> {
  textGiscart[0].classList.add("text-relate");
});
imageGiscart[0].addEventListener("mouseleave", ()=> {
  textGiscart[0].classList.remove("text-relate");
});

// When hover top texts...
textDataviz[0].addEventListener("mouseenter", ()=> {
  imageDataviz[0].children[0].classList.add("image-relate");
});
textDataviz[0].addEventListener("mouseleave", ()=> {
  imageDataviz[0].children[0].classList.remove("image-relate");
});
textUiuxvisual[0].addEventListener("mouseenter", ()=> {
  imageUiuxvisual[0].children[0].classList.add("image-relate");
});
textUiuxvisual[0].addEventListener("mouseleave", ()=> {
  imageUiuxvisual[0].children[0].classList.remove("image-relate");
});
textFrontend[0].addEventListener("mouseenter", ()=> {
  imageFrontend[0].children[0].classList.add("image-relate");
});
textFrontend[0].addEventListener("mouseleave", ()=> {
  imageFrontend[0].children[0].classList.remove("image-relate");
});
textGiscart[0].addEventListener("mouseenter", ()=> {
  imageGiscart[0].children[0].classList.add("image-relate");
});
textGiscart[0].addEventListener("mouseleave", ()=> {
  imageGiscart[0].children[0].classList.remove("image-relate");
});


//navigation
const navTop = document.getElementById('nav-top');
const navWorks = document.getElementById('nav-works');
const navResearch = document.getElementById('nav-research');
const navPublications = document.getElementById('nav-publications');
const navBlog = document.getElementById('nav-blog');
const navAbout = document.getElementById('nav-about');

let scrollTop;
let scrollWorks;
let scrollResearch;
let scrollPublications;
let scrollBlog;
let scrollAbout;

let scrollHeight;

//navigation top
navTop.addEventListener('click', ()=> {
  //scrollTop = document.getElementById('scroll-top');
  //scrollHeight = scrollTop.getBoundingClientRect().top + window.pageYOffset;
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
  menubar.setAttribute('aria-selected', 'false');
  menu.setAttribute('aria-hidden', 'true');
  menu.classList.remove('display-slidedown');
})

//navigation works
navWorks.addEventListener('click', ()=> {
  scrollWorks = document.getElementById('scroll-works');
  const w = document.documentElement.clientWidth || window.innerWidth;
  if (w <= 768) {
    scrollHeight = scrollWorks.getBoundingClientRect().top + window.pageYOffset - 186;
  } else {
    scrollHeight = scrollWorks.getBoundingClientRect().top + window.pageYOffset - 60;
  }
  
  window.scrollTo({
    top: scrollHeight,
    behavior: 'smooth'
  });
  menubar.setAttribute('aria-selected', 'false');
  menu.setAttribute('aria-hidden', 'true');
  menu.classList.remove('display-slidedown');
})

//navigation research
navResearch.addEventListener('click', ()=> {
  scrollResearch = document.getElementById('scroll-research');
  const w = document.documentElement.clientWidth || window.innerWidth;
  if (w <= 768) {
    scrollHeight = scrollResearch.getBoundingClientRect().top + window.pageYOffset - 186;
  } else {
    scrollHeight = scrollResearch.getBoundingClientRect().top + window.pageYOffset - 60;
  }
  window.scrollTo({
    top: scrollHeight,
    behavior: 'smooth'
  });
  menubar.setAttribute('aria-selected', 'false');
  menu.setAttribute('aria-hidden', 'true');
  menu.classList.remove('display-slidedown');
})

//navigation publication
navPublications.addEventListener('click', ()=> {
  scrollPublications = document.getElementById('scroll-publications');
  const w = document.documentElement.clientWidth || window.innerWidth;
  if (w <= 768) {
    scrollHeight = scrollPublications.getBoundingClientRect().top + window.pageYOffset - 186;
  } else {
    scrollHeight = scrollPublications.getBoundingClientRect().top + window.pageYOffset - 60;
  }
  window.scrollTo({
    top: scrollHeight,
    behavior: 'smooth'
  });
  menubar.setAttribute('aria-selected', 'false');
  menu.setAttribute('aria-hidden', 'true');
  menu.classList.remove('display-slidedown');
})

//navigation blog
// navBlog.addEventListener('click', ()=> {
//   scrollBlog = document.getElementById('scroll-blog');
//   scrollHeight = scrollBlog.getBoundingClientRect().top + window.pageYOffset - 52;
//   console.log("blog-height" + scrollHeight);
//   window.scrollTo({
//     top: scrollHeight,
//     behavior: 'smooth'
//   });
//   menubar.setAttribute('aria-selected', 'false');
//   menu.setAttribute('aria-hidden', 'true');
//   menu.classList.remove('display-slidedown');
// })

//navigation about
navAbout.addEventListener('click', ()=> {
  scrollAbout = document.getElementById('scroll-about');
  const w = document.documentElement.clientWidth || window.innerWidth;
  if (w <= 768) {
    scrollHeight = scrollAbout.getBoundingClientRect().top + window.pageYOffset - 186;
  } else {
    scrollHeight = scrollAbout.getBoundingClientRect().top + window.pageYOffset - 60;
  }

  window.scrollTo({
    top: scrollHeight,
    behavior: 'smooth'
  });
  menubar.setAttribute('aria-selected', 'false');
  menu.setAttribute('aria-hidden', 'true');
  menu.classList.remove('display-slidedown');
})

// Labels for each section
let worksType = document.getElementsByClassName('works_content_sectionSub');
let researchType = document.getElementsByClassName('research_content_sectionSub');
let publicationsType = document.getElementsByClassName('publications_content_sectionSub');
let aboutType = document.getElementsByClassName('about_content_sectionSub');

let worksHeight = document.getElementById('scroll-works').clientHeight;
let researchHeight = document.getElementById('scroll-research').clientHeight;
let publicationsHeight = document.getElementById('scroll-publications').clientHeight;
let aboutHeight = document.getElementById('scroll-about').clientHeight;


window.addEventListener('DOMContentLoaded', function(){
  window.addEventListener('scroll', function(){
    setTimeout(function() {
      scrollWorks = document.getElementById('scroll-works');
      let scrollSectionTtlWorks = document.getElementById('works-sectionTtl');
      let worksPos = scrollWorks.getBoundingClientRect().top; //+ window.pageYOffset - 52;

      scrollResearch = document.getElementById('scroll-research');
      let scrollSectionTtlResearch = document.getElementById('research-sectionTtl');
      let researchPos = scrollResearch.getBoundingClientRect().top; //+ window.pageYOffset - 52;

      scrollPublications = document.getElementById('scroll-publications');
      let scrollSectionTtlPublications = document.getElementById('publications-sectionTtl');
      let publicationsPos = scrollPublications.getBoundingClientRect().top; //+ window.pageYOffset - 52;
      
      scrollAbout = document.getElementById('scroll-about');
      let scrollSectionTtlAbout = document.getElementById('about-sectionTtl');
      let aboutPos = scrollAbout.getBoundingClientRect().top; //+ window.pageYOffset
      let y = window.pageYOffset;
      
      if (worksPos <= 72 && worksPos >= -worksHeight) {
        worksType[0].style.top = "72px";
        scrollSectionTtlWorks.style.top = "72px";
      } else if (researchPos <= 72 && researchPos >= -researchHeight) {
        researchType[0].style.top = "72px";
        scrollSectionTtlResearch.style.top = "72px";
      } else if (publicationsPos <= 72 && publicationsPos >= -publicationsHeight) {
        publicationsType[0].style.top = "72px";
        scrollSectionTtlPublications.style.top = "72px";
      } else if (aboutPos <= 72 && aboutPos >= -aboutHeight) {
        aboutType[0].style.top = "72px";
        scrollSectionTtlAbout.style.top = "72px";
      }
    }, 0);
  });
});


//Page top
window.onload = ()=>{
  const pageTopBtn = document.getElementsByClassName('pageTop');
  window.addEventListener('scroll', ()=> {
    //console.log(window.scrollY);
    if (window.scrollY > 100) {
      
      setTimeout(function () {
        pageTopBtn[0].setAttribute("aria-hidden", "false");
      }, 600);
      pageTopBtn[0].style.transition = "opacity 0.6s linear 0s";
      pageTopBtn[0].style.opacity = 1; 
    } else if (window.scrollY <= 100) {
        setTimeout(function () {
          pageTopBtn[0].setAttribute("aria-hidden", "true");
        }, 600);
        pageTopBtn[0].style.transition = "opacity 0.6s linear 0s";
        pageTopBtn[0].style.opacity = 0;
    }
  });

  pageTopBtn[0].addEventListener('click', ()=> {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  })

  const zivatadLogo = document.getElementById("zivatad-logo")
  zivatadLogo.addEventListener('click', ()=> {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  })

  const zivatadIcon = document.getElementById("zivatad-icon")
  zivatadIcon.addEventListener('click', ()=> {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  })
}


//Works - select works based on the types of work
// Get DOM
const headerAllworks = document.getElementById('header-allWorks');
const headerDataviz = document.getElementById('header-dataviz');
const headerUiuxvisual = document.getElementById('header-uiuxvisual');
const headerFrontend = document.getElementById('header-frontend');
const headerGiscart = document.getElementById('header-giscart');

const optionsAllworks = document.getElementById('options-allWorks');
const optionsDataviz = document.getElementById('options-dataviz');
const optionsUiuxvisual = document.getElementById('options-uiuxvisual');
const optionsFrontend = document.getElementById('options-frontend');
const optionsGiscart = document.getElementById('options-giscart');

// Change the types of portfolio with subtle animation
document.addEventListener('DOMContentLoaded', () => {
  // trigger click elements 
  const sectionSubTriggers = document.querySelectorAll('.triggerWorks');
  // target titles
  const worksHeaders = document.querySelectorAll('.headerWorks');

  for (let i = 0; i < sectionSubTriggers.length; i++) {
    // When the click element clicked
    sectionSubTriggers[i].addEventListener('click', (e) => {
        // Get clicked element
        let currentMenu = e.currentTarget;
    
        // Get the target element which contains the same data id attribute
        let currentContents = document.querySelectorAll('.' + currentMenu.dataset.id);
      
        // Remove 'is-active' classes on all click elements
        for (let i = 0; i < sectionSubTriggers.length; i++) {
            sectionSubTriggers[i].setAttribute('aria-selected', 'false');
        }
        // Add 'is-active' class on the clicked element
        currentMenu.setAttribute('aria-selected', 'true');

        // Get all works DOM
        const worksMainTargets = document.getElementsByClassName('worksMain-target');

        // Remove irrelevant contents 
        for (let i = 0; i < worksMainTargets.length; i++) {
          
          if (!worksMainTargets[i].classList.contains(currentMenu.dataset.id.substring(5))) {
            worksMainTargets[i].style.opacity = "0";
            window.setTimeout(function(){
              worksMainTargets[i].setAttribute('aria-hidden', 'true');
            },600); // time this to fit the animation
          } else {
            window.setTimeout(function(){
              worksMainTargets[i].setAttribute('aria-hidden', 'false');
              window.setTimeout(function(){
                worksMainTargets[i].style.opacity = "1";
              },420);
            },520); // time this to fit the animation
          }    
        }

        // Add animations for the transition (change section subheaders)
        window.setTimeout(function(){
          worksHeaders[0].setAttribute('aria-hidden', 'true');
        },460); // time this to fit the animation
        worksHeaders[0].children[0].style.width = "0%";
        window.setTimeout(function(){
          worksHeaders[1].setAttribute('aria-hidden', 'true');
        },460); // time this to fit the animation
        worksHeaders[1].children[0].style.width = "0%";
        window.setTimeout(function(){
          worksHeaders[2].setAttribute('aria-hidden', 'true');
        },460); // time this to fit the animation
        worksHeaders[2].children[0].style.width = "0%";
        window.setTimeout(function(){
          worksHeaders[3].setAttribute('aria-hidden', 'true');
        },460); // time this to fit the animation
        worksHeaders[3].children[0].style.width = "0%";
        window.setTimeout(function(){
          worksHeaders[4].setAttribute('aria-hidden', 'true');
        },460); // time this to fit the animation
        worksHeaders[4].children[0].style.width = "0%";
      
      // If there is dataset id on clicked elements
      if(currentContents[0] !== null) {
          window.setTimeout(function(){
            currentContents[0].setAttribute('aria-hidden', 'false');
          },600); // time this to fit the animation
          
          window.setTimeout(function(){
            currentContents[0].children[0].style.width = "100%";
          },720); // time this to fit the animation
        }
        scrollWorks = document.getElementById('scroll-works');
        scrollHeight = scrollWorks.getBoundingClientRect().top + window.pageYOffset - 52;
        //console.log("works-height" +  scrollHeight);
        window.scrollTo({
          top: scrollHeight,
          behavior: 'smooth'
        });
      });
    }
});

// Swiper
window.addEventListener('load', () => {
  // Swiper
  const swiper = new Swiper(".slider", {
    slidesPerView: 1,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
  });
}, false);

// Show more (Works) - consider to add this later
// const moreWorks  = document.getElementsByClassName('works-more');
// const moreWorksBtn = document.getElementById('works-more-btn');

// moreWorksBtn.addEventListener('click', function (e) {
//     e.preventDefault();
//     console.log('clicked');
//     if (moreWorks[0].getAttribute('aria-hidden') === 'true') {
//         moreWorks[0].setAttribute("aria-hidden", "false");
//         moreWorksBtn.setAttribute("aria-selected", "true");

//         const swiper2 = new Swiper('.slider2', {
//           slidesPerView: 1,
//           pagination: {
//             el: ".swiper-pagination",
//             clickable: true,
//           },
//         }) 
       
//         moreWorks[0].style.height = 'auto';

//         let height = moreWorks[0].clientHeight + "px";

//         moreWorks[0].style.height = '0px';

//         worksHeight = document.getElementById('scroll-works').clientHeight;

//         setTimeout(function () {
//           moreWorks[0].style.height = height;
//         }, 0);

        
//     } else {
//       moreWorksBtn.setAttribute("aria-selected", "false");
//         moreWorks[0].style.height = '0px';
//         moreWorks[0].addEventListener('transitionend', function () {
//           moreWorks[0].setAttribute("aria-hidden", "true");
//         }, {
//             once: true
//         });

//         scrollWorks = document.getElementById('scroll-works');
//         scrollHeight = scrollWorks.getBoundingClientRect().top + window.pageYOffset - 52;
//         console.log("works-height" +  scrollHeight);

//         worksHeight = document.getElementById('scroll-works').clientHeight;

//         window.scrollTo({
//           top: scrollHeight + 60,
//           behavior: 'smooth'
//         });

//     }
// });


//Research
const headerPhD = document.getElementById('header-PhD');
const headerMaster = document.getElementById('header-Master');

const optionsPhD = document.getElementById('options-PhD');
const optionsMaster = document.getElementById('options-Master');

// Change the types of portfolio with subtle animation
document.addEventListener('DOMContentLoaded', () => {
  // trigger elements 
  const sectionSubTriggers = document.querySelectorAll('.triggerResearch');
  // target contents
  const researchContentsMain = document.querySelectorAll('.research_content_main');
  // target titles
  const researchHeaders = document.querySelectorAll('.headerResearch');

  for (let i = 0; i < sectionSubTriggers.length; i++) {
      
    // When the tab menu clicked (PhD/Masters)
    sectionSubTriggers[i].addEventListener('click', (e) => {
      // Get the clicked element
      let currentMenu = e.currentTarget;
      
      // Take the targeted element (The content having id, which is the same data attribute of the tab menu)
      let currentContents = document.querySelectorAll('.' + currentMenu.dataset.id);

      // Remove aria-selected true for all tab menu
      for (let i = 0; i < sectionSubTriggers.length; i++) {
        sectionSubTriggers[i].setAttribute('aria-selected', 'false');     
      }
      
      // Added aria-selected for the clicked tab
      currentMenu.setAttribute('aria-selected', 'true');
            
        if (currentMenu.dataset.id == 'researchPhD') {
  
          window.setTimeout(function(){
            researchContentsMain[0].setAttribute('aria-hidden', 'false'); 
          },600); // time this to fit the animation
          window.setTimeout(function(){
            researchContentsMain[0].style.opacity = "1";
          },860); // time this to fit the animation
      
          researchContentsMain[1].style.opacity = "0";
          window.setTimeout(function(){
            researchContentsMain[1].setAttribute('aria-hidden', 'true');
          },600); 
            
        } else if (currentMenu.dataset.id == 'researchMaster')  {
          researchContentsMain[0].style.opacity = "0";
          window.setTimeout(function(){
            researchContentsMain[0].setAttribute('aria-hidden', 'true');
          },600); // time this to fit the animation
          window.setTimeout(function(){
            researchContentsMain[1].setAttribute('aria-hidden', 'false');
          },600); 
          window.setTimeout(function(){
            researchContentsMain[1].style.opacity = "1";    
          },860);      
        }

        window.setTimeout(function(){
          researchHeaders[0].setAttribute('aria-hidden', 'true');
        },460); // time this to fit the animation
        researchHeaders[0].children[0].style.width = "0%";
        window.setTimeout(function(){
          researchHeaders[1].setAttribute('aria-hidden', 'true');
        },460); // time this to fit the animation
        researchHeaders[1].children[0].style.width = "0%";

      // Show targeted content (contain specified id)
      
      if(currentContents[0] !== null) {
          
        window.setTimeout(function(){
          currentContents[0].setAttribute('aria-hidden', 'false');
        },600); // time this to fit the animation
        
        window.setTimeout(function(){
          currentContents[0].children[0].style.width = "100%";
        },720); // time this to fit the animation
      }

      // Scroll to top when the tab (PhD or Master) is clicked
      const researchContent = document.getElementById("researchContent");
      const researchTop = researchContent.getBoundingClientRect().top + window.pageYOffset - 48;
      //console.log(researchTop);
      window.scrollTo({
        top: researchTop,
        behavior: 'smooth'
      });

    });
  }
});

let researchTabsTop;
let researchTabBottom;

document.addEventListener('DOMContentLoaded', () => {

  const researchTabs = document.querySelectorAll(".research-tab");
  const researchContents = document.querySelectorAll('.research-content');

  for (let i = 0; i < researchTabs.length; i++) {
    researchTabs[i].addEventListener('click', (e) => {
     
      const id = e.target.dataset.id;
      const clickedParent = e.target.parentElement;
  
    if (id) {
      console.log(id);
      researchTabs.forEach(btn => {
          btn.setAttribute("aria-selected", "false");
          
          if (btn.getAttribute('data-id') == id) {
            btn.setAttribute("aria-selected", "true");
          }
      });

      researchContents.forEach(content => {
          content.style.opacity = "0";
          window.setTimeout(function(){
            content.setAttribute("aria-hidden", "true");
          },600);
      });

      const element = document.getElementById(id);
      console.log(element);
      
      window.setTimeout(function(){
        element.setAttribute("aria-hidden", "false");
      },600);
      window.setTimeout(function(){
        element.style.opacity = "1";
      },820);
    }

    if (clickedParent.classList.contains('research-tabs-bottom')) {
      //console.log("tab bottom");
      const researchTabsTop = document.getElementsByClassName("research-tabs-top");
      const researchTabsHeight = researchTabsTop[0].getBoundingClientRect().top + window.pageYOffset - 48;
      window.scrollTo({
        top: researchTabsHeight,
        behavior: 'smooth'
      });
    }

    });
  }
});

const referencesToggle = document.querySelectorAll(".references-toggle");
referencesToggle.forEach((toggle) => {
  toggle.onclick = function () {
    //console.log(this);
    this.classList.toggle("is-open");

    let content = this.nextElementSibling;

    if (content.style.maxHeight) {
      //this is if the toggle is open
      content.style.maxHeight = null;
      this.innerHTML = "show references";
    } else {
      //if the toggle is currently closed
      content.style.maxHeight = content.scrollHeight + "px";
      this.innerHTML = "hide references";
    }
  };
});

//Accordion

document.addEventListener('DOMContentLoaded', () => {
  const overviewAccordion = document.getElementsByClassName("project-overview_text");
  console.log(overviewAccordion);
  for (let i = 0; i < overviewAccordion.length; i++) {

    overviewAccordion[i].addEventListener('click', (event)=> {
        let clickedElement = overviewAccordion[i];
        event.stopPropagation();
        // console.log(clickedElement);
        // console.log('clicked');
        let selected = clickedElement.getAttribute('aria-selected');

        if (selected == "false") {
          clickedElement.setAttribute("aria-selected", "true");
          clickedElement.nextElementSibling.setAttribute("aria-hidden", "false");
    
        } else {
          clickedElement.setAttribute("aria-selected", "false");
          clickedElement.nextElementSibling.setAttribute("aria-hidden", "true");
        }
    })
  };
});

// lightbox
// fsLightbox.props.showThumbsOnMount = true;
fsLightboxInstances["VLL-lightbox"].props.onOpen;
fsLightboxInstances["ProjectMapper-lightbox"].props.onOpen;
fsLightboxInstances["TransitDiagram-lightbox"].props.onOpen;

